import {useState, useEffect} from 'react'
import Navbar from '../components/navbar'
import DataTable from 'react-data-table-component';
import moment from 'moment';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import RangePicker from "react-range-picker";

const Listing = () => {

    const [extensions, setExtensions] = useState([])
    const [brands, setBrands] = useState([])
    const [brand, setBrand] = useState('')
    const [startDate, setStartDate] = useState(moment().subtract(30,'d').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"))
    const [howitworks, setHowitworks] = useState(0)
    const [images, setImages] = useState(0)
    const [layout, setLayout] = useState(0)
    const [notinstall, setNotinstall] = useState(0)
    const [notuseful, setNotuseful] = useState(0)
    const [speedissues, setSpeedissues] = useState(0)
    const [other, setOther] = useState(0)
    const [pending, setPending] = useState(true)
    const [nb, setNb] = useState(0)

    const customStyles = {
      
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                fontWeight : 'bold',
                fontSize: '16px'
            },
        },
    };
    
    const columns = [
        {
            name: 'Brand',
            selector: row => row.brand,
            sortable: true,
            width: '180px',
            style: {
                fontWeight : 'bold',
                
            },
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: '180px',
        },
        {
            name: 'How it works',
            selector: row => row.howitworks,
            wrap : true,
            conditionalCellStyles: [
                {
                    when: row => row.howitworks === true,
                    style: {
                        background: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAKmSURBVEhL3ZVNaBNBGIa/2c0fIlQ8iFBQLx704lE8iMUmgicVNYjkFlRobWykCh4khN4UTfRkDwpSabWCB00RTIIIXuxNBX9KQATrJbYKQtL87fjO7GxMMkuaQk59YHbeb2Znvpk3OxPaMDBV94e50yYtrZzHtLuIsRqZVorG8sv9S3Lr8CCZ5izUQZRVLD9Ml7IvRFd/kqSGDxAznkFtJ05iBycolp+3O1WSRCKx1zTNGuSeRqNRaNV+v79ar9dFf6EKUDd1MpksUHr4JHHjEWYKIAEnZkVoPD8j5nUwxMPn8xUx4TLk105dqVRWhEYporTrdChKnD2RCST8emcCgdwJVnQI1Sp29E7EPZEKnsPwKcxgW875Y4rnzgol4xbkTizLKjLGxCp7Ix2K4Ou59z8BfaRNgahULnTaZZMOnZFfixup0FFM9QBKjgV/yWOdoguZkoo15IvihzUMY7dsSYfieM6SaXyg28GwbHO4e2QfnnNYv9duABYfxVlYhOVXVYuGbhenAVkT20oGftRUcJqmggN0M7SNGvw5Emy2+wGnGbqcm1aR+CBc0e0a3DKJwW+lFjAWoTJ7T16ZYIdqRQL+g6g6qiK8xr4oqaHbFX7aIG89AvVHxjY7Mc1+pQUcu4xS/E3zHc75MSU1dLsEF19/x6gRFblxH1fGK6Ud1mGXQzwn7iFR2uH0k3j1ioqarM+uVnw+4fmSHTjwsVabHNa0y+PxuB/GkfnfWDlOtoLzDHYoLkI3utsFdLsc4tmXxPhDqDKZLGY36ni93s9KanS3y6EWmMA2rlEs+021aNRqteNKanS3y2Ei84vGc3dU5ArGLyqpsbZdPYKFflJSo+2qRxG1uO6lxvlZgI1loVEvYLUlLGaoU8PuEuoh/FXcwLsbFqJ/cmQkXVOMs30AAAAASUVORK5CYII=")',
                        width: '25px',
                        height: '25px',
                        backgroundRepeat : 'no-repeat'
                    },
                },
            ]
        },
        {
            name: 'Images',
            selector: row => row.images,
            wrap : true,
            conditionalCellStyles: [
                {
                    when: row => row.images === true,
                    style: {
                        background: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAKmSURBVEhL3ZVNaBNBGIa/2c0fIlQ8iFBQLx704lE8iMUmgicVNYjkFlRobWykCh4khN4UTfRkDwpSabWCB00RTIIIXuxNBX9KQATrJbYKQtL87fjO7GxMMkuaQk59YHbeb2Znvpk3OxPaMDBV94e50yYtrZzHtLuIsRqZVorG8sv9S3Lr8CCZ5izUQZRVLD9Ml7IvRFd/kqSGDxAznkFtJ05iBycolp+3O1WSRCKx1zTNGuSeRqNRaNV+v79ar9dFf6EKUDd1MpksUHr4JHHjEWYKIAEnZkVoPD8j5nUwxMPn8xUx4TLk105dqVRWhEYporTrdChKnD2RCST8emcCgdwJVnQI1Sp29E7EPZEKnsPwKcxgW875Y4rnzgol4xbkTizLKjLGxCp7Ix2K4Ou59z8BfaRNgahULnTaZZMOnZFfixup0FFM9QBKjgV/yWOdoguZkoo15IvihzUMY7dsSYfieM6SaXyg28GwbHO4e2QfnnNYv9duABYfxVlYhOVXVYuGbhenAVkT20oGftRUcJqmggN0M7SNGvw5Emy2+wGnGbqcm1aR+CBc0e0a3DKJwW+lFjAWoTJ7T16ZYIdqRQL+g6g6qiK8xr4oqaHbFX7aIG89AvVHxjY7Mc1+pQUcu4xS/E3zHc75MSU1dLsEF19/x6gRFblxH1fGK6Ud1mGXQzwn7iFR2uH0k3j1ioqarM+uVnw+4fmSHTjwsVabHNa0y+PxuB/GkfnfWDlOtoLzDHYoLkI3utsFdLsc4tmXxPhDqDKZLGY36ni93s9KanS3y6EWmMA2rlEs+021aNRqteNKanS3y2Ei84vGc3dU5ArGLyqpsbZdPYKFflJSo+2qRxG1uO6lxvlZgI1loVEvYLUlLGaoU8PuEuoh/FXcwLsbFqJ/cmQkXVOMs30AAAAASUVORK5CYII=")',
                        width: '25px',
                        height: '25px',
                        backgroundRepeat : 'no-repeat'
                    },
                },
            ]
        },
        {
            name: 'Layout',
            selector: row => row.layout,
            wrap : true,
            conditionalCellStyles: [
                {
                    when: row => row.layout === true,
                    style: {
                        background: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAKmSURBVEhL3ZVNaBNBGIa/2c0fIlQ8iFBQLx704lE8iMUmgicVNYjkFlRobWykCh4khN4UTfRkDwpSabWCB00RTIIIXuxNBX9KQATrJbYKQtL87fjO7GxMMkuaQk59YHbeb2Znvpk3OxPaMDBV94e50yYtrZzHtLuIsRqZVorG8sv9S3Lr8CCZ5izUQZRVLD9Ml7IvRFd/kqSGDxAznkFtJ05iBycolp+3O1WSRCKx1zTNGuSeRqNRaNV+v79ar9dFf6EKUDd1MpksUHr4JHHjEWYKIAEnZkVoPD8j5nUwxMPn8xUx4TLk105dqVRWhEYporTrdChKnD2RCST8emcCgdwJVnQI1Sp29E7EPZEKnsPwKcxgW875Y4rnzgol4xbkTizLKjLGxCp7Ix2K4Ou59z8BfaRNgahULnTaZZMOnZFfixup0FFM9QBKjgV/yWOdoguZkoo15IvihzUMY7dsSYfieM6SaXyg28GwbHO4e2QfnnNYv9duABYfxVlYhOVXVYuGbhenAVkT20oGftRUcJqmggN0M7SNGvw5Emy2+wGnGbqcm1aR+CBc0e0a3DKJwW+lFjAWoTJ7T16ZYIdqRQL+g6g6qiK8xr4oqaHbFX7aIG89AvVHxjY7Mc1+pQUcu4xS/E3zHc75MSU1dLsEF19/x6gRFblxH1fGK6Ud1mGXQzwn7iFR2uH0k3j1ioqarM+uVnw+4fmSHTjwsVabHNa0y+PxuB/GkfnfWDlOtoLzDHYoLkI3utsFdLsc4tmXxPhDqDKZLGY36ni93s9KanS3y6EWmMA2rlEs+021aNRqteNKanS3y2Ei84vGc3dU5ArGLyqpsbZdPYKFflJSo+2qRxG1uO6lxvlZgI1loVEvYLUlLGaoU8PuEuoh/FXcwLsbFqJ/cmQkXVOMs30AAAAASUVORK5CYII=")',
                        width: '25px',
                        height: '25px',
                        backgroundRepeat : 'no-repeat'
                    },
                },
            ]
        },
        {
            name: 'Not install',
            selector: row => row.notinstall,
            wrap : true,
            conditionalCellStyles: [
                {
                    when: row => row.notinstall === true,
                    style: {
                        background: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAKmSURBVEhL3ZVNaBNBGIa/2c0fIlQ8iFBQLx704lE8iMUmgicVNYjkFlRobWykCh4khN4UTfRkDwpSabWCB00RTIIIXuxNBX9KQATrJbYKQtL87fjO7GxMMkuaQk59YHbeb2Znvpk3OxPaMDBV94e50yYtrZzHtLuIsRqZVorG8sv9S3Lr8CCZ5izUQZRVLD9Ml7IvRFd/kqSGDxAznkFtJ05iBycolp+3O1WSRCKx1zTNGuSeRqNRaNV+v79ar9dFf6EKUDd1MpksUHr4JHHjEWYKIAEnZkVoPD8j5nUwxMPn8xUx4TLk105dqVRWhEYporTrdChKnD2RCST8emcCgdwJVnQI1Sp29E7EPZEKnsPwKcxgW875Y4rnzgol4xbkTizLKjLGxCp7Ix2K4Ou59z8BfaRNgahULnTaZZMOnZFfixup0FFM9QBKjgV/yWOdoguZkoo15IvihzUMY7dsSYfieM6SaXyg28GwbHO4e2QfnnNYv9duABYfxVlYhOVXVYuGbhenAVkT20oGftRUcJqmggN0M7SNGvw5Emy2+wGnGbqcm1aR+CBc0e0a3DKJwW+lFjAWoTJ7T16ZYIdqRQL+g6g6qiK8xr4oqaHbFX7aIG89AvVHxjY7Mc1+pQUcu4xS/E3zHc75MSU1dLsEF19/x6gRFblxH1fGK6Ud1mGXQzwn7iFR2uH0k3j1ioqarM+uVnw+4fmSHTjwsVabHNa0y+PxuB/GkfnfWDlOtoLzDHYoLkI3utsFdLsc4tmXxPhDqDKZLGY36ni93s9KanS3y6EWmMA2rlEs+021aNRqteNKanS3y2Ei84vGc3dU5ArGLyqpsbZdPYKFflJSo+2qRxG1uO6lxvlZgI1loVEvYLUlLGaoU8PuEuoh/FXcwLsbFqJ/cmQkXVOMs30AAAAASUVORK5CYII=")',
                        width: '25px',
                        height: '25px',
                        backgroundRepeat : 'no-repeat'
                    },
                },
            ]
        },
        {
            name: 'Not useful',
            selector: row => row.notuseful,
            wrap : true,
            conditionalCellStyles: [
                {
                    when: row => row.notuseful === true,
                    style: {
                        background: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAKmSURBVEhL3ZVNaBNBGIa/2c0fIlQ8iFBQLx704lE8iMUmgicVNYjkFlRobWykCh4khN4UTfRkDwpSabWCB00RTIIIXuxNBX9KQATrJbYKQtL87fjO7GxMMkuaQk59YHbeb2Znvpk3OxPaMDBV94e50yYtrZzHtLuIsRqZVorG8sv9S3Lr8CCZ5izUQZRVLD9Ml7IvRFd/kqSGDxAznkFtJ05iBycolp+3O1WSRCKx1zTNGuSeRqNRaNV+v79ar9dFf6EKUDd1MpksUHr4JHHjEWYKIAEnZkVoPD8j5nUwxMPn8xUx4TLk105dqVRWhEYporTrdChKnD2RCST8emcCgdwJVnQI1Sp29E7EPZEKnsPwKcxgW875Y4rnzgol4xbkTizLKjLGxCp7Ix2K4Ou59z8BfaRNgahULnTaZZMOnZFfixup0FFM9QBKjgV/yWOdoguZkoo15IvihzUMY7dsSYfieM6SaXyg28GwbHO4e2QfnnNYv9duABYfxVlYhOVXVYuGbhenAVkT20oGftRUcJqmggN0M7SNGvw5Emy2+wGnGbqcm1aR+CBc0e0a3DKJwW+lFjAWoTJ7T16ZYIdqRQL+g6g6qiK8xr4oqaHbFX7aIG89AvVHxjY7Mc1+pQUcu4xS/E3zHc75MSU1dLsEF19/x6gRFblxH1fGK6Ud1mGXQzwn7iFR2uH0k3j1ioqarM+uVnw+4fmSHTjwsVabHNa0y+PxuB/GkfnfWDlOtoLzDHYoLkI3utsFdLsc4tmXxPhDqDKZLGY36ni93s9KanS3y6EWmMA2rlEs+021aNRqteNKanS3y2Ei84vGc3dU5ArGLyqpsbZdPYKFflJSo+2qRxG1uO6lxvlZgI1loVEvYLUlLGaoU8PuEuoh/FXcwLsbFqJ/cmQkXVOMs30AAAAASUVORK5CYII=")',
                        width: '25px',
                        height: '25px',
                        backgroundRepeat : 'no-repeat'
                    },
                },
            ]
        },
        {
            name: 'Speed issues',
            selector: row => row.speedissues,
            wrap : true,
            conditionalCellStyles: [
                {
                    when: row => row.speedissues === true,
                    style: {
                        background: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAKmSURBVEhL3ZVNaBNBGIa/2c0fIlQ8iFBQLx704lE8iMUmgicVNYjkFlRobWykCh4khN4UTfRkDwpSabWCB00RTIIIXuxNBX9KQATrJbYKQtL87fjO7GxMMkuaQk59YHbeb2Znvpk3OxPaMDBV94e50yYtrZzHtLuIsRqZVorG8sv9S3Lr8CCZ5izUQZRVLD9Ml7IvRFd/kqSGDxAznkFtJ05iBycolp+3O1WSRCKx1zTNGuSeRqNRaNV+v79ar9dFf6EKUDd1MpksUHr4JHHjEWYKIAEnZkVoPD8j5nUwxMPn8xUx4TLk105dqVRWhEYporTrdChKnD2RCST8emcCgdwJVnQI1Sp29E7EPZEKnsPwKcxgW875Y4rnzgol4xbkTizLKjLGxCp7Ix2K4Ou59z8BfaRNgahULnTaZZMOnZFfixup0FFM9QBKjgV/yWOdoguZkoo15IvihzUMY7dsSYfieM6SaXyg28GwbHO4e2QfnnNYv9duABYfxVlYhOVXVYuGbhenAVkT20oGftRUcJqmggN0M7SNGvw5Emy2+wGnGbqcm1aR+CBc0e0a3DKJwW+lFjAWoTJ7T16ZYIdqRQL+g6g6qiK8xr4oqaHbFX7aIG89AvVHxjY7Mc1+pQUcu4xS/E3zHc75MSU1dLsEF19/x6gRFblxH1fGK6Ud1mGXQzwn7iFR2uH0k3j1ioqarM+uVnw+4fmSHTjwsVabHNa0y+PxuB/GkfnfWDlOtoLzDHYoLkI3utsFdLsc4tmXxPhDqDKZLGY36ni93s9KanS3y6EWmMA2rlEs+021aNRqteNKanS3y2Ei84vGc3dU5ArGLyqpsbZdPYKFflJSo+2qRxG1uO6lxvlZgI1loVEvYLUlLGaoU8PuEuoh/FXcwLsbFqJ/cmQkXVOMs30AAAAASUVORK5CYII=")',
                        width: '25px',
                        height: '25px',
                        backgroundRepeat : 'no-repeat'
                    },
                },
            ]
        },
        {
            name: 'other',
            selector: row => row.other,
            wrap : true,
            conditionalCellStyles: [
                {
                    when: row => row.other === true,
                    style: {
                        background: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAKmSURBVEhL3ZVNaBNBGIa/2c0fIlQ8iFBQLx704lE8iMUmgicVNYjkFlRobWykCh4khN4UTfRkDwpSabWCB00RTIIIXuxNBX9KQATrJbYKQtL87fjO7GxMMkuaQk59YHbeb2Znvpk3OxPaMDBV94e50yYtrZzHtLuIsRqZVorG8sv9S3Lr8CCZ5izUQZRVLD9Ml7IvRFd/kqSGDxAznkFtJ05iBycolp+3O1WSRCKx1zTNGuSeRqNRaNV+v79ar9dFf6EKUDd1MpksUHr4JHHjEWYKIAEnZkVoPD8j5nUwxMPn8xUx4TLk105dqVRWhEYporTrdChKnD2RCST8emcCgdwJVnQI1Sp29E7EPZEKnsPwKcxgW875Y4rnzgol4xbkTizLKjLGxCp7Ix2K4Ou59z8BfaRNgahULnTaZZMOnZFfixup0FFM9QBKjgV/yWOdoguZkoo15IvihzUMY7dsSYfieM6SaXyg28GwbHO4e2QfnnNYv9duABYfxVlYhOVXVYuGbhenAVkT20oGftRUcJqmggN0M7SNGvw5Emy2+wGnGbqcm1aR+CBc0e0a3DKJwW+lFjAWoTJ7T16ZYIdqRQL+g6g6qiK8xr4oqaHbFX7aIG89AvVHxjY7Mc1+pQUcu4xS/E3zHc75MSU1dLsEF19/x6gRFblxH1fGK6Ud1mGXQzwn7iFR2uH0k3j1ioqarM+uVnw+4fmSHTjwsVabHNa0y+PxuB/GkfnfWDlOtoLzDHYoLkI3utsFdLsc4tmXxPhDqDKZLGY36ni93s9KanS3y6EWmMA2rlEs+021aNRqteNKanS3y2Ei84vGc3dU5ArGLyqpsbZdPYKFflJSo+2qRxG1uO6lxvlZgI1loVEvYLUlLGaoU8PuEuoh/FXcwLsbFqJ/cmQkXVOMs30AAAAASUVORK5CYII=")',
                        width: '25px',
                        height: '25px',
                        backgroundRepeat : 'no-repeat'
                    },
                },
            ]
        },
        {
            name: 'Date',
            selector: row => row.when,
            sortable: true,
        },
    ];

    const onDateChanges = (date, date2) => {
		setStartDate(moment(date).format("YYYY-MM-DD"))
        setEndDate(moment(date2).format("YYYY-MM-DD"))
	}

	const updateContent = () => {
        populate(brand, startDate, endDate)
	}


   
    const handleBrand = (e) => {
        setBrand(e.target.value)
        populate(e.target.value, startDate, endDate)
    }


    const getBrands = async () => {
        const endpoint = '//feedbacks.dev.mystart.com/api_v2/getBrands.php';
        const response = await fetch(endpoint)
        const json = await response.json()
        setBrands(json.data)
    }


    const populate = async (brand, startDate, endDate) => {
        const endpoint = '//feedbacks.dev.mystart.com/api_v2/list2.php';
        
        const response = await  fetch(endpoint, {
            method : "POST",
            "body": JSON.stringify({keyword : brand, startDate : startDate, endDate : endDate})
        })
        const json = await response.json()
        setExtensions(json.data)
        setPending(false)
    }

    useEffect(() => {
        const endpoint = '//feedbacks.dev.mystart.com/api_v2/summary.php';
        fetch(endpoint, {
            method : "POST"
        })
        .then(response => response.json())
        .then(response => { 
           
            setHowitworks(response.howitworks);
            setImages(response.images)
            setLayout(response.layout)
            setNotinstall(response.notinstall)
            setNotuseful(response.notuseful)
            setSpeedissues(response.speedissues)
            setOther(response.other)
            setNb(response.nb)
        });
        getBrands()
        populate(null, startDate, endDate)
    }, [])
    

    return (
            <>
            <Navbar />
            <div className="container-fluid">
                <div className="card-group" style={{ fontSize:'13px' }}>
                    <div className="card p-2 p-lg-3">
                        <div className="p-lg-3 p-2">
                            <div className="d-flex align-items-center">
                                <div className="ms-4" style={{width: Math.round((howitworks/nb)*100) + '%'}}>
                                    <h4 className="fw-normal" style={{ minWidth:'160px',fontSize:'18px'}}>How it works ({Math.round((howitworks/nb)*100) + '%'})</h4>
                                    <div className="progress">
                                        <div
                                            className="progress-bar bg-success"
                                            role="progressbar"
                                            style={{width: "100%"}}
                                            aria-valuenow="40"
                                            aria-valuemin="0"
                                            aria-valuemax="40">
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div> 
                    <div className="card p-2 p-lg-3">
                        <div className="p-lg-3 p-2">
                            <div className="d-flex align-items-center">
                                <div className="ms-4" style={{width: Math.round((images/nb)*100) + '%'}}>
                                    <h4 className="fw-normal" style={{ minWidth:'160px',fontSize:'18px'}}> Images ({Math.round((images/nb)*100) + '%'})</h4>
                                    <div className="progress">
                                        <div
                                            className="progress-bar bg-danger"
                                            role="progressbar"
                                            style={{width: "100%"}}
                                            aria-valuenow="40"
                                            aria-valuemin="0"
                                            aria-valuemax="40"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card p-2 p-lg-3">
                        <div className="p-lg-3 p-2">
                            <div className="d-flex align-items-center">
                                <div className="ms-4" style={{width: Math.round((layout/nb)*100) + '%'}}>
                                    <h4 className="fw-normal" style={{ minWidth:'160px',fontSize:'18px'}}> Layout ({Math.round((layout/nb)*100) + '%'})</h4>
                                    <div className="progress">
                                        <div
                                            className="progress-bar bg-success"
                                            role="progressbar"
                                            style={{width: "100%"}}
                                            aria-valuenow="40"
                                            aria-valuemin="0"
                                            aria-valuemax="40"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card p-2 p-lg-3">
                        <div className="p-lg-3 p-2">
                            <div className="d-flex align-items-center">
                            
                            <div className="ms-4" style={{width: Math.round((notinstall/nb)*100) + '%'}}>
                                <h4 className="fw-normal" style={{ minWidth:'160px',fontSize:'18px'}}>Not install ({Math.round((notinstall/nb)*100) + '%'})</h4>
                                <div className="progress">
                                <div
                                    className="progress-bar bg-success"
                                    role="progressbar"
                                    style={{width: "100%"}}
                                    aria-valuenow="40"
                                    aria-valuemin="0"
                                    aria-valuemax="40"
                                ></div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="card p-2 p-lg-3">
                        <div className="p-lg-3 p-2">
                            <div className="d-flex align-items-center">
                                <div className="ms-4" style={{width: Math.round((notuseful/nb)*100) + '%'}}>
                                    <h4 className="fw-normal" style={{ minWidth:'160px',fontSize:'18px'}}>Not useful ({Math.round((notuseful/nb)*100) + '%'})</h4>
                                    <div className="progress">
                                        <div
                                            className="progress-bar bg-success"
                                            role="progressbar"
                                            style={{width: "100%"}}
                                            aria-valuenow="40"
                                            aria-valuemin="0"
                                            aria-valuemax="40"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>            
                    </div>
                </div>
                <div className="card-group">
                    <div className="card p-2 p-lg-3">
                        <div className="p-lg-3 p-2">
                            <div className="d-flex align-items-center">
                                <div className="ms-4" style={{width: Math.round((speedissues/nb)*100) + '%'}}>
                                    <h4 className="fw-normal" style={{ minWidth:'160px',fontSize:'18px'}}>Speed Issues ({Math.round((speedissues/nb)*100) + '%'})</h4>
                                    <div className="progress">
                                        <div
                                            className="progress-bar bg-success"
                                            role="progressbar"
                                            style={{width: "100%"}}
                                            aria-valuenow="40"
                                            aria-valuemin="0"
                                            aria-valuemax="40"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card p-2 p-lg-3">
                        <div className="p-lg-3 p-2">
                            <div className="d-flex align-items-center">
                                <div className="ms-4" style={{width: Math.round((other/nb)*100) + '%'}}>
                                    <h4 className="fw-normal" style={{ minWidth:'160px',fontSize:'18px'}}> Other ({Math.round((other/nb)*100) + '%'})</h4>
                                    <div className="progress">
                                    <div
                                        className="progress-bar bg-danger"
                                        role="progressbar"
                                        style={{width: "100%"}}
                                        aria-valuenow="40"
                                        aria-valuemin="0"
                                        aria-valuemax="40"
                                    ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <form>
                    <div className="row">
                        <div className="col">
                            <div className="form-group mb-3">
                                <label style={{fontWeight:'bold', fontSize: '20px', margin:'10px'}}>Brands</label>
                                <div className="controls">
                                    <select
                                        name="brand"
                                        id="brand"
                                        className="form-select mb-3" 
                                        onChange={handleBrand} 
                                        value={brand}>
                                            <option value="">Select a brand</option>
                                            {
                                                
                                                brands.map((item, i) => (
                                                    <option value={item} key={item}>
                                                        {item}
                                                    </option>
                                                ))
                                            }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="col-md-12">
				<div style={{ textAlign:'right'}}>
                    <RangePicker  defaultValue={{ startDate: new Date(startDate), endDate: new Date(endDate)}} dateFormat="YYYY-MM-dd" onDateSelected={onDateChanges}  onClose={updateContent}/>
                </div>
            </div>
            <DataTable
                title="Uninstall - Feedbacks"
                columns={columns}
                data={extensions}
                pagination
                progressPending={pending}
                customStyles={customStyles}
            />
        </>
    )
}

export default Listing


