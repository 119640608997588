import React from 'react';
import Listing from './Pages/Listing';
import Login from './Pages/Login';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

function App() {
    return ( 
        <Router>
            <div className = "App" >
                <Switch >
                    <Route path = "/" exact component = { Listing }/> 
                    <Route path = "/login" exact component = { Login }/>  
                </Switch> 
            </div> 
        </Router>
    );
}

export default App;